.marquee-text {
  font-size: 20px;
  padding: 30px;
  color: var(--bg-primary-light);
  text-shadow: #333 1px 0 10px;
}

.login-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ddd;
}

.login-form h1 {
  text-align: center;
  font-size: 25px;
}

.img-logo {
  width: 100%;
}

h1 {
  font-size: 24px;
}

@media (max-width: 768px) {
  .img-fluid {
    display: none;
  }
}
@media (max-width: 1200px) {
  .login-form {
    padding: 20px;
    background-color: white;
    box-shadow: none;
    margin-top: 10px;
  }
}
@media (max-width: 960px) {
}
