.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: sans-serif;
}

.not-found-header {
  font-size: 15em;
  color: #ccc;
  margin: 0;
}

.not-found-text {
  font-size: 1.5em;
  color: #999;
  margin: 0;
}
