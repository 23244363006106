@import url("https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@300;400;500;600;700&display=swap");

*,
body,
head {
  font-family: "Mukta Vaani", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
