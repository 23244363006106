.navbar {
  background-color: #3d405b !important;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.navbar-brand {
  color: #fff;
  font-size: 1.5em;
  text-decoration: none;
}

.navbar-nav {
  display: flex;
}

.nav-item {
  margin-left: 25px;
  position: relative;
}

.nav-link {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.navbar-brand:hover,
/* .navbar-brand:focus, */
.nav-link:hover,
.nav-link:focus {
  /* color: var(--hover-text-color); */
  color: var(--hover-text-color);
}

.nav-item:hover {
  color: #f2cc8f !important;
}

.nav-item .active {
  /* border-bottom: 3px solid #81b29a; */
  font-weight: 700;
  color: #f2cc8f !important;
}

.dropdown-menu {
  background-color: #fff;
  border: 1px solid #3d405b;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
}

.dropdown-item {
  color: #3d405b;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.dropdown-divider {
  border-top: 1px solid #444;
}

.toggle-container {
  padding: 0.5rem 0.25rem;
}

.lang-toggle-text {
  color: var(--text-color);
  font-size: var(--font-size);
  margin: 5px;
}

.lang-toggle {
  margin: 5px;
}

.navbar-toggler {
  color: var(--background-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--background-color);
}

@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
  }
  .navbar-nav {
    flex-direction: column;
  }
  .nav-item {
    margin-left: 0;
    margin-top: 10px;
  }
  .dropdown-menu {
    position: relative;
  }
}
