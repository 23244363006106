:root {
  --background-color: #f6f6e9;
  --main-color: #3d4059;
  --secondary-color: #f2cc8f;
  --hover-color: #494c69;
  --font-size: 18px;
  --text-color: var(--background-color);
  --font-family: Mukta Vaani, sans-serif;
  --button-background-color: var(--main-color);
  --button-text-color: #f6f6e9;
  --nav-background-color: var(--main-color);
  --nav-text-color: #f6f6e9;
  --border-color: var(--main-color);
  --hover-background-color: var(--hover-color);
  --hover-text-color: #f4f1de;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  margin: 0;
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

body {
  /* background: var(--background-color); */
  background: #bbbbbb;
  /* background: -moz-linear-gradient(
    90deg,
    rgba(246, 246, 233, 1) 59%,
    rgba(173, 173, 173, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(246, 246, 233, 1) 59%,
    rgba(173, 173, 173, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(246, 246, 233, 1) 59%,
    rgba(173, 173, 173, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6e9",endColorstr="#adadad",GradientType=1); */
}

.bg-container {
  /* background image */
  /* background-image: url("../../public/images/bg-image.jpg"); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 800px;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.tab-container {
  /* remove this two lines to make content full screen */
  /* max-width: 1560px; */
  /* width: 100%; */

  /* margin: 50px 0px; */
  margin-top: none;
  /* width: min(100% - 30px, 1320px); */
  position: relative;
  padding: 0.5rem;
  /* border: 1px solid var(--main-color);  */
  border-radius: 8px;
  margin-right: auto;
  margin-left: auto;
  color: #212529;
}

/* basic styles */
.btn-primary {
  background-color: var(--main-color);
  border-color: var(--border-color);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #494c69;
  color: #ffffff;
}

:not(.btn-check) + .btn:active,
.btn:first-child:active {
  background-color: var(--main-color);
  border-color: #494c69;
}

.btn svg {
  margin-right: 5px;
}
.btn.float-end svg {
  margin-left: 5px;
  margin-right: 0;
}

.btn:hover svg path,
.btn:focus svg path,
.btn:active svg path,
.btn.active svg path {
  fill: #ffffff;
}
.btn-outline-primary {
  color: var(--secondary-color);
  border-color: rgba(108, 99, 255, 0.25);
}

.btn svg {
  margin-right: 5px;
}
.btn.float-end svg {
  margin-left: 5px;
  margin-right: 0;
}

.btn:hover svg path,
.btn:focus svg path,
.btn:active svg path,
.btn.active svg path {
  fill: #ffffff;
}

.btn-outline-primary {
  color: var(--main-color);
  border-color: rgba(108, 99, 255, 0.25);
}

.btn svg {
  margin-right: 5px;
}
.btn.float-end svg {
  margin-left: 5px;
  margin-right: 0;
}

.btn:hover svg path,
.btn:focus svg path,
.btn:active svg path,
.btn.active svg path {
  fill: #ffffff;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
  background-color: var(--main-color);
  border-color: var(--border-color);
  color: #ffffff;
}

.btn-gray-outline {
  background-color: #ffffff;
  border-color: #d5d5d5;
  color: #000000;
}
.btn-gray-outline:hover,
.btn-gray-outline:focus,
.btn-gray-outline:active,
.btn-gray-outline.active {
  background-color: #f6f6f6;
  border-color: #c6c6c6;
  color: #000000;
}
.btn-gray-outline:hover svg path,
.btn-gray-outline:focus svg path,
.btn-gray-outline:active svg path,
.btn-gray-outline.active svg path {
  fill: #000000;
}

.btn-sm {
  height: 30px;
  line-height: 28px;
  padding: 0 10px;
  font-size: 12px;
}

.badge-outline-primary,
.badge-outline-secondary {
  border-radius: 5px;
  padding: 3px 6px;
  margin-right: 10px;
}
.badge-outline-primary {
  border: 1px solid #6ccab9;
  background-color: #d4f7f1;
}
.badge-outline-secondary {
  border: 1px solid #ccc;
  background-color: #f0f0f0;
}
.badge-outline-primary svg,
.badge-outline-secondary svg {
  margin-right: 4px;
  margin-top: -2px;
}
.badge-outline-secondary svg {
  display: none;
}
.bg-primary {
  background-color: var(--main-color);
}
.bg-primary-light {
  background-color: #a9a5f8;
}
.bg-secondary {
  background-color: #050e45;
}
.bg-secondary-light {
  background-color: #222955;
}
.bg-dark {
  background-color: #000000;
}
.bg-dark-light {
  background-color: #e6e6e6;
}
.bg-green {
  background-color: #6ccab9;
}
.bg-green-light {
  background-color: #abf5e7;
}
.bg-pink {
  background-color: #fa4a96;
}
.bg-pink-light {
  background-color: #fdc4dd;
}
.bg-orange {
  background-color: #f35757;
}
.bg-orange-light {
  background-color: #ffcdcd;
}
.bg-yellow {
  background-color: #ffb60f;
}
.bg-yellow-light {
  background-color: #ffe4a5;
}

/* text styles */
.text-primary {
  color: var(--main-color);
}
.text-secondary {
  color: #e07a5f;
}
.text-dark {
  color: #f2cc8f;
}
.text-green {
  color: #81b29a;
}

.textorange {
  color: #e07a5f;
}
.text-teal {
  color: #f4f1de;
}

/* main styles */
.container {
  max-width: 1560px;
  /* margin: 50px 0; */
  /* width: min(100% - 30px, 1320px); */
  border: 1px solid gray;
}

.data-block {
  margin: 10px 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
}
h5,
.h5 {
  font-size: 18px;
}
h6,
.h6 {
  font-size: 16px;
}

/* custom tab */
div .custom-tab {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: var(--main-color);
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: var(--hover-color);
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: var(--main-color);
  border-bottom: 1px solid var(--bs-nav-tabs-border-color);
}

div .custom-tab .nav-link {
  color: black;
}

div .custom-tab .nav-link.active {
  color: var(--main-color) !important;
  border-width: 2px;
  border-bottom: 1px solid;
}

/* form inputs */
.input-block {
  margin: 5px;
}

label {
  margin: 0 5px;
  color: #333;
  font-weight: 600;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  /* margin: 10px 0px; */
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.form-validation {
  color: #e83f36;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.image-dropdown {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.button-primary {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  /* margin: 20px 0px; */
  /* margin-right: 20px; */
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: var(--font-size);
  font-weight: 600;
  cursor: pointer;
}

.button-primary:hover {
  background-color: var(--hover-color);
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

.button-secondary {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  /* margin: 20px 0px; */
  /* margin-right: 20px; */
  font-weight: 600;
  background-color: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  font-size: var(--font-size);
  cursor: pointer;
}

.button-secondary:hover {
  color: white;
  background-color: var(--main-color);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.react-date-picker__wrapper {
  border: 0;
}

.table-responsive {
  max-height: 50vh;
}
